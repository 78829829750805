<template>
  <div class="content-container">
    <PageTitle :title="$t('MEDIAQUIZ.TITLE')" class="page-title">
      <template #extra-content>
        <router-link v-if="mediaQuizData.items && mediaQuizData.items.length > 0" :to="{name: ROUTE_NAMES_CMS.CREATE_MEDIAQUIZ}" class="pink-button">
          {{ $t('MEDIAQUIZ.CREATE') }}
        </router-link>
      </template>
    </PageTitle>
    <h1 v-if="mediaQuizData.items && mediaQuizData.items.length > 0" class="page-section-title section-title">{{ $t('MEDIAQUIZ.OVERVIEW') }}</h1>

    <CustomTable
      :options="mediaquizTableOptions"
      :table-data="mediaQuizData.items"
      :pagination-data="mediaQuizData"
      :search-function="manageSearch"
      :filter-data="filterData"
      :empty-state-data="emptyStateData"
      :search-bar-options="{ placeholder: 'Zoeken op vraag', id: 1 }">
      <template #question="props">
        <div class="question">{{ getTitle(props.rowData) }}</div>
      </template>
      <template #createdAt="props">
        <div>{{ new Date(props.rowData.createdAt).toLocaleDateString('nl') + ' ' + new Date(props.rowData.createdAt).toLocaleTimeString('nl') }}</div>
      </template>
      <template #status="props">
        <div class="status" :class="{ 'concept': props.rowData.isActivated === 0 }">{{ status[props.rowData.isActivated].name }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton :text="$t('DELETE')"
                        :icon="require('../../../assets/icons/icn_delete.svg')"
                        custom-class="button-red"
                        class="action"
                        @click="preDeleteQuestion(props.rowData)"/>
          <ActionButton :text="$t('EDIT')"
                        :icon="require('../../../assets/icons/icn_edit.svg')"
                        custom-class="button-blue"
                        class="action"
                        @click="editQuestion(props.rowData)"/>
        </div>
      </template>
      <template #right-side-info>
        <p> <span style="font-weight: 500">{{ mediaQuizData.totalItems }}</span> {{ $t('MEDIAQUIZ.TOTAL_QUESTIONS') }}</p>
      </template>
    </CustomTable>
    <BasicModal v-if="isBasicVersionDisplayed" />
  </div>

</template>

<script>

import BasicModal from '@/components/elements/basicModal/BasicModal'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import { RESET_BASIC_MODAL_DATA, SET_BASIC_MODAL_DATA } from '@/store/mutations'
import { computed, ref } from '@vue/reactivity'
import { GET_MEDIAQUIZZES, DELETE_MEDIAQUIZ } from '@/store/modules/cms/actions'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import PageTitle from '@/components/elements/texts/PageTitle'
import CustomTable from '@/components/table/CustomTable'
import ActionButton from '@/components/table/ActionButton'
import { getTableOptions, MEDIAQUIZ } from '@/utils/helpers/customTable/CustomTableHelper'
import { ROUTE_NAMES_CMS } from '@/router/modules/cms'
import { useI18n } from 'vue-i18n'
import { showJustDate } from '../../../utils/helpers/DateFormatter'

export default {
  name: 'MediaQuiz',
  components: {
    PageTitle,
    CustomTable,
    ActionButton,
    BasicModal
  },
  setup() {
    const { t } = useI18n()
    const router = useRouter()
    const store = useStore()

    const activeModal = computed(() => store.getters.getActiveBasicModal)
    const isBasicVersionDisplayed = computed(() =>
      activeModal.value === MODAL_NAMES.DELETE_ATTACHMENT
    )

    const questionType =
      [
        {
          id: 6,
          name: t('MEDIAQUIZ.DRAG_AND_DROP')
        },
        {
          id: 7,
          name: t('MEDIAQUIZ.SELECT_ANSWER')
        },
      ]

    const status =
      [
        {
          id: 0,
          name: t('CONCEPT')
        },
        {
          id: 1,
          name: t('PUBLISHED')
        }
      ]

    const filterData = computed(() => {
      return {
        placeholder: 'Filter op categorie',
        singleSelection: false,
        dropdownOptions:
          [
            {
              name: 'Antwoordtype',
              type: 'missionSlideTypeId',
              options: questionType
            },
            {
              name: 'Status',
              type: 'isActivated',
              options: status
            },
          ]
      }
    })

    function manageSearch(payload) {
      if (!payload) {
        payload = {}
      }
      getMediaquiz(payload)
    }

    function getMediaquiz(payload) {
      store.dispatch(GET_MEDIAQUIZZES, payload)
    }

    const mediaQuizData = computed(() => store.getters.getMediaquizes)

    const emptyStateData = computed(() => {
      return {
        title: t('EMPTY_TABLE.MEDIAQUIZ.TITLE'),
        text: t('EMPTY_TABLE.MEDIAQUIZ.TEXT'),
        buttonText: t('EMPTY_TABLE.MEDIAQUIZ.BUTTON_TEXT'),
        buttonAction: createQuestion,
        icon: require('@/assets/icons/icn_faq.svg')
      }
    })

    function createQuestion() {
      router.push({ name: ROUTE_NAMES_CMS.CREATE_MEDIAQUIZ })
    }

    function editQuestion(data) {
      router.push({ name: ROUTE_NAMES_CMS.EDIT_MEDIAQUIZ, params: {id: data.id} })
    }

    function preDeleteQuestion(questionData) {
      const payload = {
        modalOptions: {
          modalContent: BASIC_MODAL_CONTENT.DELETE_MEDIAQUIZ(questionData.question),
          cancelCallback: null,
          executeCallback: () => deleteQuestion(questionData),
        },
        activeModal: MODAL_NAMES.DELETE_ATTACHMENT
      }
      store.commit(SET_BASIC_MODAL_DATA, payload)
    }

    function deleteQuestion(questionData) {
      store.dispatch(DELETE_MEDIAQUIZ, { id: questionData.id }).then(() => {
        store.commit(RESET_BASIC_MODAL_DATA)
        manageSearch({})
      })
    }

    function getTitle(rowData) {
      const question = rowData.contentQuestionJson
      const correct = rowData.contentAnswerJson

      if (rowData.missionSlideTypeId === 6) {
        for (let i = 0, j = 0; i < question.question.length; i++) {
          if (question.question[i] === null) {
            question.question[i] = question.answers[correct.correctAnswer[j] - 1].answer
            j++
          }
        }

        return question.question.join(' ').replaceAll(/<.+?>/g, "")
      } else {
        return question.question.replaceAll(/<.+?>/g, "")
      }
    }

    return {
      isBasicVersionDisplayed,

      mediaQuizData,
      emptyStateData,
      mediaquizTableOptions: getTableOptions(MEDIAQUIZ),

      preDeleteQuestion,
      editQuestion,
      showJustDate,
      status,
      getTitle,

      filterData,
      manageSearch,

      ROUTE_NAMES_CMS,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.pink-button {
  color: white;
}

.status {
  text-transform: uppercase;
  font-weight: 500;
  color: var(--blue_dark);
  font-size: rem(12);

  &.concept {
    color: var(--blue_light_01);
  }
}
</style>
